import React from 'react';

import './OrderDetail.scss';
import { I18NContext } from '../../I18NContext';
import Help from '../Help';
import {MedicationGroup, OrderSummaryDetails,
        OrderSummaryPage} from '../component';
import MedicationCardChargeLine from '../common/MedicationCardChargeLine';
import HeavyDivider from '../common/HeavyDivider';
import { OrderDetailBreadCrumb } from '../common/BreadCrumb';
import {hasAnnotation} from '../common/PaymentUtils';
import { PaymentDeclineAlert } from '../common/PaymentDeclineAlert';
import {AboveTheFoldMarker, FunctionalMarker} from "../csa/markers";
import OrderSummaryRow from "../OrderSummaryRow";
import CancelV2 from '../common/CancelV2';
import RefundRowV2 from './RefundRowV2';
import { ViewInvoiceMenu } from '../InvoiceMenu';
import {GroupType} from "../../common/Types";
import PillPackMedicationGroupAccordionCard from "../PillPackMedicationGroupAccordionCard";
import { generateMedSyncAnnotationAlert } from '../common/AnnotationUtils';
import MedicationCard from "../common/MedicationCard";
import PaymentV2 from "../common/PaymentV2";
import MedicationCardAutoRefillLine from "../common/MedicationCardAutoRefillLine";
import MedicationCardPromotionLine from "../common/MedicationCardPromotionLine";
import ExternalLink from '../common/ExternalLink';
import MedicationGroupAccordionAlertMessage from '../medicationGroup/MedicationGroupAccordionAlertMessage';
import GenericAlert from '../common/GenericAlert';

interface OrderDetailV2Props {
    urlId: string;
    orderSummaryPage: OrderSummaryPage;
}

class OrderDetailV2 extends React.Component<OrderDetailV2Props, {}>  {
    render() {
        const { urlId , orderSummaryPage } = this.props;
        /**
        * For PillPack order, orderSummaryPage.orders can contains two or more orders.
         * For the other Order, orderSummaryPage.orders only contains one order.
        */

        const canCancel = hasAnnotation(orderSummaryPage.cancelDetails.cancellationGroup.annotations, 'Cancel');
        const isPillPack = (orderSummaryPage.medicationGroupCard?.medicationGroupList?.length ?? 0) > 0;
        return (
            <pui-stack data-csa-c-content-id="order-detail-page" data-csa-c-type="card" data-csa-c-slot-id="order-detail">
                    {this._generateAlerts(orderSummaryPage, urlId)}
                    <OrderDetailBreadCrumb 
                        yourOrders={orderSummaryPage.orderSummaryPageLabels.yourOrders}
                        delivery={orderSummaryPage.orderSummaryPageLabels.delivery}
                        tracking={orderSummaryPage.orderSummaryPageLabels.tracking}
                        order={orderSummaryPage.orderSummaryPageLabels.order}
                        bypassTracker={orderSummaryPage.bypassTracker}
                    />
                    <pui-heading input={"Order Summary"} textSize='large' spacingTop="small" spacingBottom="small"/>
                    {this._generateOrderSummaryTable(orderSummaryPage.orderSummaryDetails)}
                    <RefundRowV2 refundBottomSheetDetails={orderSummaryPage.refundDetails?.refundBottomSheetDetails}></RefundRowV2>

                    <HeavyDivider />
                {this._generateMedicationGroupAccordionCard(orderSummaryPage)}
                    <AboveTheFoldMarker/>

                    <HeavyDivider />
                    <PaymentV2
                        paymentInformation={orderSummaryPage.paymentInformation}
                        insuranceInformation={orderSummaryPage.insuranceInformation}
                        paymentMethodLabel={orderSummaryPage.orderSummaryPageLabels.paymentMethod}
                        paymentInfoHeader={orderSummaryPage.orderSummaryPageLabels.paymentInformation}
                        updatePaymentMethod={orderSummaryPage.orderSummaryPageLabels.updatePaymentMethod}
                        paymentLabels={orderSummaryPage.orderSummaryPageLabels.paymentLabels}
                        insuranceAndDiscountCardInformation={orderSummaryPage.orderSummaryPageLabels.insuranceAndDiscountCardInformation}
                        /**
                         * TODO: replace with payment gqlorderid's
                         */
                        urlId={urlId}
                    />
                    <HeavyDivider />
                <pui-heading textSize="medium" spacingBottom="small"
                             input={orderSummaryPage.orderSummaryPageLabels.help}/>
                <Help invoiceId={urlId}
                      viewInvoice={orderSummaryPage.orderSummaryPageLabels.viewInvoice}
                      managePillpackSettings={orderSummaryPage.orderSummaryPageLabels.viewPillpackSettings}
                      contactCustomerCare={orderSummaryPage.orderSummaryPageLabels.contactCustomerCare}
                      isPillPack={isPillPack}>
                    {canCancel && <CancelV2 cancelDetails={this.props.orderSummaryPage.cancelDetails}
                                            orderSummaryPageLabels={this.props.orderSummaryPage.orderSummaryPageLabels}/>}
                    {isPillPack && <ViewInvoiceMenu invoiceMenu={orderSummaryPage.invoiceMenu}
                                                        title={orderSummaryPage.orderSummaryPageLabels.invoiceMenuTitle}
                                                        buttonName={orderSummaryPage.orderSummaryPageLabels.viewInvoiceMenu}/>}
                    </Help>
                    <FunctionalMarker/>
            </pui-stack>);
    }
    _generateOrderSummaryTable(orderSummaryDetails: OrderSummaryDetails) {
        const charge = orderSummaryDetails.charges;
        return (
            <pui-stack>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <OrderSummaryRow label={orderSummaryDetails.idLabel} value={orderSummaryDetails.id} />
                        <OrderSummaryRow label={orderSummaryDetails.dateLabel} value={orderSummaryDetails.date} />
                        <OrderSummaryRow label={charge.itemLabel} value={charge.item} />
                        { charge.couponSaving && <OrderSummaryRow label={charge.couponSavingLabel} value={charge.couponSaving} />}
                        <OrderSummaryRow label={charge.unitPrice} value={charge.unitPrice} />
                        <OrderSummaryRow label={charge.shippingLabel} value={charge.shipping} />
                        { charge.pillpackServiceFee && <OrderSummaryRow label={charge.pillpackServiceFeeLabel} value={charge.pillpackServiceFee} />}
                        <OrderSummaryRow label={charge.totalBeforeTaxLabel} value={charge.totalBeforeTax} />
                        <OrderSummaryRow label={charge.salesTaxLabel} value={charge.salesTax} />
                        <OrderSummaryRow label={charge.hbaEligibleTotalLabel} value={charge.hbaEligibleTotal} />
                        <OrderSummaryRow label={charge.grandTotalLabel} value={charge.grandTotal} fontWeight="bold" />
                    </tbody>
                </table>
            </pui-stack>
        )
    }
    /**
     * Generates the accordion card for the order.
     */
    _generateMedicationGroupAccordionCard(orderSummaryPage: OrderSummaryPage) {
        const medicationGroupCard = orderSummaryPage.medicationGroupCard;
        const medicationGroupCardHeader = medicationGroupCard.medicationGroupHeader;
        const headline = medicationGroupCard.medicationGroupHeader.headline;
        const subline = medicationGroupCard.medicationGroupHeader.subline;

        const alert = generateMedSyncAnnotationAlert(medicationGroupCardHeader.annotations);
        if (!medicationGroupCard.medicationGroupList || medicationGroupCard.medicationGroupList.length === 0) {
            return (
                <ExternalLink href={`/dp/${medicationGroupCardHeader.asin}`} key={medicationGroupCardHeader.id}>
                    <MedicationCard
                        headline={headline}
                        subline={subline}
                        imageURL={medicationGroupCardHeader.photoUrl}
                    >
                        <MedicationCardAutoRefillLine
                            isAutoRefill={hasAnnotation(medicationGroupCardHeader.annotations, 'AutoRefill')}
                            autoRefillTextDisplay={medicationGroupCardHeader.autoRefillLine}
                        />
                        
                        {medicationGroupCardHeader.priceString && (
                            <MedicationCardChargeLine chargeAmount={medicationGroupCardHeader.priceString}/>
                        )}

                        {medicationGroupCardHeader.paymentInformation && (
                            <pui-text textSize="small" input={medicationGroupCardHeader.paymentInformation}/>
                        )}

                        {medicationGroupCardHeader.promotionSavingLine && (
                            <MedicationCardPromotionLine promotionSavingsLine={medicationGroupCardHeader.promotionSavingLine} />
                        )}
                    </MedicationCard>
                    {alert && <MedicationGroupAccordionAlertMessage {...alert}/>}
                </ExternalLink>
            )
        }
        const headerProps = {
            pillPack: {
              heading: headline,
              subHeading: subline,
              groupType: GroupType.PILLPACK,
              children: medicationGroupCardHeader.priceString ? (
                <MedicationCardChargeLine chargeAmount={medicationGroupCardHeader.priceString} />
              ) : null,
            },
        };

        const medicationCards = {
            pillPack: this._generateMedicationGroupCard(medicationGroupCard.medicationGroupList)
        }
        return <PillPackMedicationGroupAccordionCard
            id={`accordion${orderSummaryPage.orderSummaryPageLabels.id}`}
                                                     alertMessage={alert}
                                                     medicationCards={medicationCards}
                                                     headerProps={headerProps} 
                                                     showDivider={false}
                                                     marginLeft={true}
                                                     noCollapse={true}/>
    }

    _generateMedicationGroupCard(medicationGroupList: MedicationGroup[]) {
        return (
            <>
                {medicationGroupList.map(medication => (
                    <ExternalLink href={`/dp/${medication.asin}`} key={medication.id}>
                        <MedicationCard
                            headline={medication.headline}
                            subline={medication.subline}
                            imageURL={medication.photoUrl}
                            promotionSavingLine={medication.promotionSavingLine}
                            annotations={medication.annotations}
                        >
                        </MedicationCard>
                    </ExternalLink>
                ))}
            </>
        )
    }

    _generateAlerts = (orderSummaryPage: OrderSummaryPage, gqlOrderId: string) => {
        const alerts = [];

        if (orderSummaryPage.alerts && orderSummaryPage.alerts.length > 0) {
            alerts.push(
                ...orderSummaryPage.alerts.map((alert, index) => {                              
                    if (alert.alertType === 'PAYMENT') {
                        return (
                            <PaymentDeclineAlert
                                key={`alert-${index}`}
                                paymentLabels={orderSummaryPage.orderSummaryPageLabels.paymentLabels}
                                gqlOrderId={gqlOrderId}
                                alert={alert}
                            />
                        );
                    } else {
                        return <GenericAlert key={`alert-${index}`} alert={alert} />;
                    }
                })
            );
        }
        return alerts;
    };

}

OrderDetailV2.contextType = I18NContext;

export default OrderDetailV2;