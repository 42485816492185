import { Annotation } from "../component";

export const getMedSyncAnnotation = (annotations: Annotation[] | undefined) => {
    return annotations?.find(
        (annotation) => annotation.annotationType === "MedsSyncing" || annotation.annotationType === "MedsSynced");
}

export const generateMedSyncAnnotationAlert = (annotations: Annotation[] | undefined) => {
    const medsSyncAnnotation = getMedSyncAnnotation(annotations);
    return medsSyncAnnotation ? {
        message: medsSyncAnnotation.label,
        type: medsSyncAnnotation.annotationType === "MedsSynced" ? 'success' : 'info'
    } : undefined;
}