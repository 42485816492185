import React from 'react';
import { GroupType } from "../../common/Types";
import PillPackMedicationGroupAccordionCard from "../PillPackMedicationGroupAccordionCard";
import { generateMedSyncAnnotationAlert } from '../common/AnnotationUtils';
import { Annotation, ItemGroup, ItemGroupSummary } from "../component";
import { ForwardTextLink } from "./ForwardTextLink";
import ItemAnnotations from "./ItemAnnotations";
import ItemMedicationSummaryCard from "./ItemMedicationSummaryCard";
import { CardStyle } from "./MedicationCard";
import MedicationCardAutoRefillLine from "./MedicationCardAutoRefillLine";
import MedicationGroupAccordionAlertMessage from "../medicationGroup/MedicationGroupAccordionAlertMessage";

interface DeliveryCardProps {
    itemGroupSummary: ItemGroupSummary;
    trackingLinkLabel: string;
    orderDetailsLinkLabel: string;
    bypassTracker: boolean;
}

export default function DeliveryCard({itemGroupSummary, bypassTracker}: DeliveryCardProps) {

    //TODO: Revisit after QA handoff for to investigate an ideal long term fix
    const getOrderPageUrl = (itemGroupSummary: ItemGroupSummary) => {
        if (itemGroupSummary.itemGroups.length > 0) {
            return `/order/${itemGroupSummary.itemGroups[0].id}`;
        } else if (itemGroupSummary.pillPackGroupSummary != null) {
            return `/order/${itemGroupSummary.pillPackGroupSummary.id}`;
        } else {
            return `/order/${itemGroupSummary.bottleGroupSummary.id}`;
        }
    }

    let nextPageUrl: string;
    if (bypassTracker) {
        nextPageUrl = getOrderPageUrl(itemGroupSummary); // todo update the nextPageUrl for case otherItemGroups is empty
    } else {
        nextPageUrl = `/progress-tracker/${itemGroupSummary.id}`;
    }

    const generateHeader = () => {
        return (
            <pui-stack direction="horizontal" secondaryAxisArrangement="start">
                <pui-heading input={itemGroupSummary.headlineAbbr} headingLevel="3" textSize="small"/>
            </pui-stack>
        );
    }

    const ignoredAnnotationsForSummaries = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn", "Replaces", "ReplacedBy"]);
    const ignoredAnnotationsForGroups = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn", "PayFixup"]);
    const filterAnnotations = (annotations: Annotation[], ignoredAnnotations: Set<string>) => {
        return annotations.filter(annotation => !ignoredAnnotations.has(annotation.annotationType));
    }

    const generateAlertComponent = (annotations: Annotation[] | undefined) => {
        if (!annotations || annotations.length == 0) {
            return undefined;
        }
        const medSyncAnnotationAlertMessage = generateMedSyncAnnotationAlert(annotations);
        const otherAnnotations = annotations.filter((annotation) =>
          !(annotation.annotationType === "MedsSyncing" || annotation.annotationType === "MedsSynced"))

        return <>
            {medSyncAnnotationAlertMessage &&
              <MedicationGroupAccordionAlertMessage {...medSyncAnnotationAlertMessage} key={"med-sync-alert"}/>}
            {otherAnnotations.map((annotation, index) =>
              <MedicationGroupAccordionAlertMessage type={'info'}
                                                    message={annotation.label} key={`alert-${index}`}/>)}
        </>
    }

    /**
     * @param itemGroup
     * itemGroup.itemSummaries contains the items in the same order
     * @param itemGroupType
     */
    const generateItemGroup = (itemGroup: ItemGroup, itemGroupType?: string) => {
        const keyPostfix = itemGroupType ? `-${itemGroupType}-orderhistorymedcard` : '-orderhistorymedcard';
        return itemGroup.itemSummaries.map((itemSummary, i) => {
            const RefillCardLine = <MedicationCardAutoRefillLine
                isAutoRefill={itemSummary.isAutoRefill}
                autoRefillTextDisplay={itemGroupSummary.orderingChannelLine}/>;

            itemSummary.annotations = filterAnnotations(itemSummary.annotations, ignoredAnnotationsForSummaries);

            return  <ItemMedicationSummaryCard
                purchaseItemSummary={itemSummary}
                autoRefillCardLine={RefillCardLine}
                showSupply={true}
                cardStyle={CardStyle.HEADLINE_MED_50PX_IMG}
                key={i + keyPostfix}/>;
        });
    }

    /**
     * @param itemGroup
     * generate itemGroupAnnotation
     */
    const generateItemGroupAnnotation = (itemGroup: ItemGroup) => {
        return (itemGroup.annotations.length > 0) && <pui-stack spacingLeft="small" direction="horizontal">
            <ItemAnnotations
                annotations={filterAnnotations(itemGroup.annotations, ignoredAnnotationsForGroups)}
                textSize="large" textColor="black"/>
        </pui-stack>
    }

    /**
     * generate PillPackMedicationGroupAccordionCard
     */
    const generatePillPackMedicationGroupAccordionCard = () => {
        const pillPackItemGroups = itemGroupSummary.pillPackGroupSummary?.itemGroups;
        const pillPackBottleItemGroups = itemGroupSummary.bottleGroupSummary?.itemGroups;
        const pillPackMedicationCards = pillPackItemGroups?.length > 0 ? pillPackItemGroups.map((itemGroup) =>
            <>{generateItemGroup(itemGroup, 'pillpack')}
                {generateItemGroupAnnotation(itemGroup)}</>
        ) : undefined
        const bottleMedicationCards = pillPackBottleItemGroups?.length > 0 ? pillPackBottleItemGroups.map((itemGroup) =>
            <>{generateItemGroup(itemGroup, 'bottle')}
                {generateItemGroupAnnotation(itemGroup)}</>
        ) : undefined
        const headerProps = {
            pillPack: itemGroupSummary.pillPackGroupSummary ? {
                heading: itemGroupSummary.pillPackGroupSummary.headline,
                subHeading: itemGroupSummary.pillPackGroupSummary.subline,
                groupType: GroupType.PILLPACK
            } : undefined,
            bottle: itemGroupSummary.bottleGroupSummary ? {
                heading: itemGroupSummary.bottleGroupSummary.headline,
                subHeading: itemGroupSummary.bottleGroupSummary.subline,
                groupType: GroupType.BOTTLE
            } : undefined
        }

        return <PillPackMedicationGroupAccordionCard id={itemGroupSummary.id}
            key={`accordion-${itemGroupSummary.id}`}
            nextPageUrl={nextPageUrl}
            medicationCards={{pillPack: pillPackMedicationCards, bottle: bottleMedicationCards}}
            showDivider={itemGroupSummary.itemGroups.length > 0}
            headerProps={headerProps}
            alertComponent={generateAlertComponent(itemGroupSummary.annotations)}/>
    }


    return(
        <pui-product-card noshadow="true" key={itemGroupSummary.id} spacingBottom="med-small" paddingTop="med-small" paddingLeft="mini" paddingRight="med-small">
        <ForwardTextLink url={nextPageUrl} key={`header-forward-${itemGroupSummary.id}`}>
            {itemGroupSummary.headline && generateHeader()}
        </ForwardTextLink>
        {generatePillPackMedicationGroupAccordionCard()}
        <ForwardTextLink url={nextPageUrl} key={`other-group-forward-${itemGroupSummary.id}`}>
            {itemGroupSummary.itemGroups.map((itemGroup) => <React.Fragment key={itemGroup.id}>
                    {generateItemGroup(itemGroup)}
                    {(itemGroup.annotations.length > 0) && <pui-stack direction="horizontal">
                        <ItemAnnotations annotations={filterAnnotations(itemGroup.annotations, ignoredAnnotationsForGroups)} textSize="large" textColor="black"/>
                    </pui-stack>}
                </React.Fragment>
            )}
        </ForwardTextLink>
    </pui-product-card>);
}
