import React from 'react';
import { I18NContext } from '../../I18NContext';
import {Annotation, BackupPaymentMethodLabels, InfoGroup, InfoItem, PaymentLabels} from '../component';
import ErrorBoundary from '../../ErrorBoundary';
import "./Common.css";
import PaymentBottomSheet from './PaymentBottomSheet';
import {BackupPaymentMethodAlert} from "./BackupPaymentMethodAlert";
import {backupPaymentMethodUsed, canUpdate, needFixup, suppressPayFixup} from "./PaymentUtils";

interface PaymentProps {
    paymentInformation: InfoGroup;
    insuranceInformation: InfoGroup;
    paymentMethodLabel: string;
    paymentInfoHeader: string;
    updatePaymentMethod: string;
    paymentLabels: PaymentLabels;
    insuranceAndDiscountCardInformation: string
    urlId: string;
}

interface PaymentState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
    apxView?: string;
}

/**
 * Payment Card to sho Payment information containing payment method and insurance information
 */
class PaymentV2 extends React.Component<PaymentProps, PaymentState> {

    private button: any;
    private bottomSheet: any;
    private host: string;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
        this.host = window.location.origin;
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();

        this.setState({
            apxView: undefined,
        })

        // getAPXWidget(this.props.id)
        //     .then(view => this.setState({ apxView: view }))
        //     .catch(() => this.setState({ apxView: 'Error loading Payments.  Please try again later.' }));
    }

    _closeBottomSheet() {
        this.state.bottomSheet.current.hide();
    }

    _messageEventListenerFunction = (event: any) => {
        if(event.origin === this.host && event.data === 'close') {
            this._closeBottomSheet();
            window.location.reload();
        }
    }

    _canUpdatePayment = (annotations: Annotation[]) => {
        return !suppressPayFixup(this.props.paymentInformation.annotations) &&
        canUpdate(this.props.paymentInformation.annotations);
    }

    _generatePaymentMethodHeader() {
        return (
            <pui-stack direction="horizontal" secondaryAxisArrangement="start" spacingTop={'medium'}
                       spacingBottom={'medium'}>
                
                <pui-heading input={this.props.paymentMethodLabel} headingLevel="3" textSize="small"/>
                {this._canUpdatePayment(this.props.paymentInformation.annotations) &&
                    <pui-link id={`link-for-ingress-into-editPayment`}
                              text={this.props.paymentLabels.editPaymentMethodText}
                              data-csa-c-type="link" data-csa-c-action="navigate"
                              data-csa-c-content-id={`link-for-ingress-into-edit-payment`} data-csa-c-slot-id="payment"
                              style={{whiteSpace: "nowrap"}} textSize="medium" onClick={(e: any) => {
                        e.preventDefault();
                        this._openBottomSheet(e)
                    }}/>}
            </pui-stack>
        );
    }

    // Todo Update BackupPaymentMethodAlert
    _generatePaymentRows() {
        if (this._canUpdatePayment(this.props.paymentInformation.annotations)) {
            return (
                <pui-stack>
                    <pui-stack>
                        {this.props.paymentInformation.items.map(infoItem => this._generateInfoItemRow(infoItem))}
                        {needFixup(this.props.paymentInformation.annotations) && <pui-stack-item
                        ref={this.state.button} onClick={(e: any) => this._openBottomSheet(e)}
                        direction='vertical'
                        class="clickable">
                                <pui-stack
                                    ref={this.state.button}
                                    direction='horizontal'
                                    mainAxisArrangement='start'
                                    >
                                    <pui-icon imgClass="status-error-icon" spacingRight="small" />
                                    <pui-text textSize="small" textColor="red" input={this.props.updatePaymentMethod} />
                                </pui-stack>
                            </pui-stack-item>}
                    </pui-stack>
                </pui-stack>

            )
        } else {
            return <pui-stack>
                {this.props.paymentInformation.items.map(infoItem => this._generateInfoItemRow(infoItem))}
            </pui-stack>
        }
    }

    componentDidMount() {
        window.addEventListener('message', this._messageEventListenerFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this._messageEventListenerFunction);
    }

    render() {
        return (
            <>
                <ErrorBoundary>
                    <pui-stack>
                        <pui-heading textSize="medium" input={this.props.paymentInfoHeader} />
                        {this._generatePaymentMethod()}
                        {this._generateInsuranceInformation()}
                    </pui-stack>
                </ErrorBoundary>
                <pui-bottom-sheet id="payment-update-modal" data-csa-c-content-id="payment-update-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" hideLink ref={this.state.bottomSheet}>
                    <PaymentBottomSheet addPaymentTitle={this.props.paymentLabels.addPaymentMethodTitle}
                                        addPaymentText={this.props.paymentLabels.addPaymentMethodText}
                                        gqlOrderId={this.props.urlId}></PaymentBottomSheet>
                </pui-bottom-sheet>
            </>
        );
    }

    _generatePaymentMethod() {
        return <>
            {this._generatePaymentMethodHeader()}
            {this._generatePaymentRows()}
        </>

    }

    _generateInsuranceInformation() {
        if (!this.props.insuranceInformation.items || this.props.insuranceInformation.items.length === 0) {
            return null;
        }
        return (<>
            <pui-divider spacingTop='small' spacingBottom='small'/>
            <pui-heading spacingBottom="medium" textSize="medium"
                         input={this.props.insuranceAndDiscountCardInformation}/>
            {this.props.insuranceInformation.items.map(infoItem => this._generateInfoItemRow(infoItem))}
        </>);
    }

    _generateInfoItemRow(infoItem: InfoItem) {
        const isPaymentInfoItem = this.props.paymentInformation.items.includes(infoItem);
        const canUpdatePayment = this._canUpdatePayment(this.props.paymentInformation.annotations);
    
        return (
            <pui-stack-item spacingBottom={"small"} overflow="true">
                {isPaymentInfoItem && canUpdatePayment ? (
                    <pui-text
                        ref={this.state.button}
                        onClick={(e: any) => this._openBottomSheet(e)}
                        direction='vertical'
                        class="clickable"
                        input={infoItem.label}
                    />
                ) : (
                    <pui-text
                        ref={this.state.button}
                        direction='vertical'
                        input={infoItem.label}
                    />
                )}
                {this._generateBackUpPaymentMethodAnnotation(infoItem)}
            </pui-stack-item>
        );
    }

    _generateBackUpPaymentMethodAnnotation(infoItem: InfoItem) {
        if (infoItem.annotations && backupPaymentMethodUsed(infoItem.annotations)) {
            const backupPaymentMethodAnnotation = infoItem.annotations.find(
                annotation => annotation.annotationType === "BackupPaymentMethod"
            );
    
            if (backupPaymentMethodAnnotation) {
                const { label, labelTitle } = backupPaymentMethodAnnotation;
                const [warningText, learnMoreText] = label.split('%');

                const backupPaymentMethodLabels: BackupPaymentMethodLabels = {
                    backupPaymentMethodUsedTitle: labelTitle || '',
                    backupPaymentMethodInformationalText: this.props.paymentLabels.backupPaymentMethodLearnMore,
                    learnMore: learnMoreText || '',
                };
    
                return (
                    <BackupPaymentMethodAlert backupPaymentMethodLabels={backupPaymentMethodLabels}
                    backupPaymentMethodWarning={warningText}></BackupPaymentMethodAlert>
                );
            }
        }
        return null;
    }
    
}

PaymentV2.contextType = I18NContext;

export default PaymentV2;
