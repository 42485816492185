import React from 'react';
import {ExternalForwardLink} from "../common/ForwardLink";
import Urls from "../../Urls";

interface SeeFuturePillPackDeliveriesCardProps {
    label: string;
}
class SeeFuturePillPackDeliveriesCard extends React.Component<SeeFuturePillPackDeliveriesCardProps, {}> {

    render() {
        return (
            <pui-product-card noshadow="true" spacingBottom="med-small" paddingTop="med-small" paddingLeft="mini"
                                 paddingRight="med-small">
            <ExternalForwardLink url={Urls.DELIVERY_SCHEDULE_URL + "?ref_=pillpack_pyo_to_mfd"} csaId={'pillpack-future-delivery-schedule'}>
                <pui-stack
                    direction="horizontal"
                    secondaryAxisArrangement={'center'}
                    mainAxisArrangement="start">
                    <pui-stack-item>
                        <pui-icon imgClass='pillpack-icon' style={{height: '48px', width: '48px'}}/>
                    </pui-stack-item>
                    <pui-stack spacingLeft={'medium'}>
                        <pui-heading input={this.props.label} headingLevel="3" textSize="small"/>
                    </pui-stack>
                </pui-stack>
            </ExternalForwardLink></pui-product-card>
        )
    }
}

export default SeeFuturePillPackDeliveriesCard;