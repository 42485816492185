import React, { ReactNode } from "react";
import { ForwardTextLink } from "./common/ForwardTextLink";
import { CardStyle } from "./common/MedicationCard";
import MedicationGroupAccordionAlertMessage, {
    MedicationGroupAccordionAlertMessageProps
} from "./medicationGroup/MedicationGroupAccordionAlertMessage";
import MedicationGroupAccordionCard from "./medicationGroup/MedicationGroupAccordionCard";
import MedicationGroupCard from "./medicationGroup/MedicationGroupCard";
import { MedicationGroupHeader, MedicationGroupHeaderProps } from "./medicationGroup/MedicationGroupHeader";

interface PillPackMedicationCards {
    pillPack?: ReactNode;
    bottle?: ReactNode;
}

interface HeaderProps {
    pillPack?: MedicationGroupHeaderProps;
    bottle?: MedicationGroupHeaderProps;
}

interface PillPackMedicationGroupAccordionCardProps {
    nextPageUrl?: string; // used for the next page link to make the accordion card clickable
    medicationCards: PillPackMedicationCards;
    showDivider?: boolean;
    noCollapse?: boolean; // default is false, used to show the accordion card without collapsing
    marginLeft?: boolean; // default is false, used to show the accordion card with margin left
    alertMessage?: MedicationGroupAccordionAlertMessageProps;
    alertComponent?: ReactNode; // will use alertComponent if it is provided
    headerProps: HeaderProps;
    id: string;
}

/**
 * This component is used to render the pill pack and bottle group accordion cards.
 * Shows accordion when having medication cards in PillPack or Bottle
 * It is used in the following scenarios:
 * For Order History, it is used to render the pill pack and bottle group accordion cards.
 * - collapsable and clickable
 * For Order Summary, it is used to render the pill pack and bottle group accordion cards.
 * - non-collapsable
 * - pill-pack with header and bottle without header. and without margin left
 * @param props
 * @constructor
 */
export default function PillPackMedicationGroupAccordionCard(props: PillPackMedicationGroupAccordionCardProps) {
    if (!props.medicationCards.bottle && !props.medicationCards.pillPack) return null;
    const alertMessage = props.alertMessage &&
        <MedicationGroupAccordionAlertMessage {...props.alertMessage} />;

    const divider = props.showDivider ? <pui-divider spacingTop='small' /> : undefined;

    const pillPackHeaderComponent = props.headerProps.pillPack && (
        <MedicationGroupHeader
            {...props.headerProps.pillPack}
            cardStyle={props.noCollapse ? CardStyle.HEADLINE_LARGE : CardStyle.HEADLINE_MED_50PX_IMG}
        />
    );
    const pillPackHeader = props.nextPageUrl ?
        <ForwardTextLink url={props.nextPageUrl} children={pillPackHeaderComponent} /> : pillPackHeaderComponent;

    const bottleHeaderComponent = props.headerProps.bottle &&
        <MedicationGroupHeader {...props.headerProps.bottle} />;
    const bottleHeader = props.nextPageUrl && props.nextPageUrl ?
        <ForwardTextLink url={props.nextPageUrl} children={bottleHeaderComponent} /> : bottleHeaderComponent;

    const marginLeftValue = props.marginLeft !== undefined
        ? (props.marginLeft ? (props.noCollapse ? "57px" : "0px") : "0px")
        : "52px";

    const pillPackMedicationGroup =
        <>{props.medicationCards.pillPack &&
            <MedicationGroupCard header={pillPackHeader} marginLeft={marginLeftValue}>
                {props.nextPageUrl ?
                    <ForwardTextLink url={props.nextPageUrl} children={props.medicationCards.pillPack} /> :
                    <>{props.medicationCards.pillPack}</>}
            </MedicationGroupCard>}</>

    const pillPackBottleMedicationGroup =
        <>{props.medicationCards.bottle &&
            <MedicationGroupCard header={bottleHeader} marginLeft={marginLeftValue}>
                {props.nextPageUrl ?
                    <ForwardTextLink url={props.nextPageUrl}
                        children={props.medicationCards.bottle} /> : <>{props.medicationCards.bottle}</>}
            </MedicationGroupCard>}</>

    return <MedicationGroupAccordionCard alertMessage={props.alertComponent ? props.alertComponent : alertMessage}
                                         divider={divider} noCollapse={props.noCollapse}>
        {pillPackMedicationGroup}
        {pillPackBottleMedicationGroup}
    </MedicationGroupAccordionCard>
}