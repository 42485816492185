export interface AlertTypeMap {
    [key: string]: {
        imgClass: string;
        status: string;
    };
}

export class AlertTypeUtility {
    private static alertTypeMap: AlertTypeMap = {
        PAYMENT: {
            imgClass: 'status-error-icon',
            status: 'error'
        },
        INFORMATION: {
            imgClass: 'status-info-icon',
            status: 'information'
        },
        REFUND: {
            imgClass: 'status-info-icon',
            status: 'information'
        },
        REPLACEMENT: {
            imgClass: 'status-info-icon',
            status: 'information'
        },
        CANCEL: {
            imgClass: 'status-info-icon',
            status: 'information'
        },
        REVERSAL: {
            imgClass: 'status-info-icon',
            status: 'information'
        }
    };

    public static getAlertTypeMapValues(alertType: 'PAYMENT' | 'REFUND' | 'REPLACEMENT' | 'CANCEL' | 'REVERSAL') {
        return AlertTypeUtility.alertTypeMap[alertType] || AlertTypeUtility.alertTypeMap.INFORMATION;
    }
}