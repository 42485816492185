import React from "react";
import {getOrderSummaryDetails, getOrderSummaryPage} from '../../accessors/GraphQLAccessor';
import OrderDetailLoading from './OrderDetailLoading';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
import { AppContext } from "../../AppContext";
import OrderDetailV2 from "./OrderDetailV2";

class OrderDetailHOCV2 extends React.Component<any, any> {
  

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <OrderDetailLoading/>,
    }
  }

  componentDidMount() {
    const actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("OrderDetail");
    const attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();

    const orderId = this.props.match.params.orderId;

    getOrderSummaryPage(orderId)
      .then((response: any) => {
        this.setState({
          view: <OrderDetailV2 urlId={orderId} orderSummaryPage={response.data.orderSummaryPage}/>
        });
        attemptMetric.setSuccess();
        actionMetricsPublisher.publish(attemptMetric);
      })
      .catch(() => {
        this.setState({
          view: (<pui-text id="order-detail-page-error" data-csa-c-content-id="order-detail-page-error" data-csa-c-type="card" data-csa-c-slot-id="order-detail">Error</pui-text>),
        });
        attemptMetric.setFailure();
        actionMetricsPublisher.publish(attemptMetric);
      });

  }

  refresh() {
    const orderId = this.props.match.params.orderId;
    
    getOrderSummaryDetails(orderId)
      .then((response: any) => {
        this.setState({
          view: <OrderDetailV2 urlId={orderId} orderSummaryPage={response.data.orderSummaryPage}/>
        })
      })
      .catch(() => {
        this.setState({
          view: (<pui-text data-csa-c-content-id="order-detail-refresh-page-error" data-csa-c-type="card" data-csa-c-slot-id="order-detail">Error</pui-text>),
        })
      });
  }

  render() {
    return (this.state.view);
  }
}

OrderDetailHOCV2.contextType = AppContext;

export default OrderDetailHOCV2

